


























































import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class GeneralTag extends Vue {
  @Prop() tag!: string;

  @Prop() canRemove!: false;

  @Prop({ default: "square" }) type?: "round" | "square";

  @Emit("remove")
  handleRemove() {
    return this.tag;
  }

  private get splitTag() {
    return this.tag.split(":");
  }
}
